<template>
  <div class="quiz">
    <div class="quiz-container">
      <XIcon class="x-icon" @click="goHome" />
      <h1>Personality Quiz</h1>
      <hr class="separator" />
      <p class="paragraph">{{ quiz.name }}</p>

      <div class="display-quiz" v-if="inProgress">
        <span class="header"> Question {{ qNum + 1 }} of {{ this.quiz.questions.length }} </span>
        <p class="question">{{ this.quiz.questions[qNum].questionText }}</p>
        <div v-for="(answer, index) in this.quiz.questions[qNum].answers" :key="index" class="bullets">
          <GRadio v-model="selectedOption" :label="answer.answerText" name="choice" :value="answer.answerId" />
        </div>
        <PassportButton
          :disabled="selectedOption === ''"
          :label="qNum + 1 === this.quiz.questions.length ? 'Submit Quiz' : 'Next Question'"
          class="quiz-button"
          @click="submit"
        />
      </div>

      <div v-else-if="isComplete" class="display-quiz">
        <span class="header"> your result is... </span>
        <img :src="resultImage" class="result-image" />
        <span class="result-title"> {{ resultTitle }} </span>
        <span class="result-answer">
          {{ resultAnswer }}
        </span>
        <Points
          v-if="resultPoints !== null"
          :points="resultPoints"
          attempted="You already earned points for this quiz"
        />
      </div>
    </div>
  </div>
</template>

<script>
import XIcon from '@/assets/icons/x_icon.svg';
import GRadio from '@/components/GRadio.vue';
import PassportButton from '@/components/PassportButton.vue';
import Points from '@/components/Points.vue';
import { ApiService } from '@/services/api.service';

export default {
  name: 'PersonalityQuiz',
  components: {
    XIcon,
    GRadio,
    PassportButton,
    Points,
  },
  data() {
    return {
      isComplete: false,
      selectedOption: '',
      inProgress: true,
      isAnswer: false,
      qNum: 0,
      id: this.$route.params.id,
      quiz: [],
      resultTitle: '',
      resultAnswer: '',
      resultPoints: null,
    };
  },
  mounted() {
    if (!this.$route.query.tid) this.$router.push('/');
    else this.getQuiz();
  },
  methods: {
    async getQuiz() {
      const { data } = await ApiService.get(`/quiz/personality/${this.id}`);
      this.quiz = data;
    },
    goHome() {
      this.$router.push('/');
    },
    async submit() {
      const answers = [];
      const quizAnswers = {
        targetId: this.$route.query.tid,
        answers,
      };

      // console.log(quizAnswers);
      if (this.selectedOption !== '') {
        answers.push({
          questionId: this.quiz.questions[this.qNum].questionId,
          selectedAnswer: this.selectedOption,
        });

        if (this.qNum < this.quiz.questions.length - 1) {
          // console.log(this.qNum);
          this.qNum += 1;
        } else {
          const { data } = await ApiService.post(`/quiz/personality/${this.id}/answer`, quizAnswers);
          const { pointsAwarded, result } = data;
          const { resultTitle, resultDescription, resultImage } = result;
          this.resultTitle = resultTitle;
          this.resultAnswer = resultDescription;
          this.resultPoints = pointsAwarded;
          this.resultImage = `${process.env.VUE_APP_ASSET_URL}/${resultImage}`;
          this.isComplete = true;
          this.inProgress = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.quiz {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  background-image: url(../assets/images/bg_main_repeating@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.quiz-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 8em;
}
.paragraph {
  margin-top: 2em;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.bullets {
  width: 80%;
  margin-top: 1em;
  font-size: 18px;
  line-height: 2.5;
  text-align: left;
}

.x-icon {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 4em;
  height: 1.2em;
  margin-top: 1em;
}
.separator {
  width: 6em;
}
.quiz-button {
  width: 15.625em;
  height: 3em;
  margin-top: 2em;
  font-family: $head-font-stack;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.message {
  width: 80%;
  margin-top: 2em;
  font-size: 18px;
  text-align: left;
}
.display-quiz {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}
.header {
  font-size: 12px;
  text-transform: uppercase;
}
.question {
  width: 80%;
  margin-top: 2em;
  font-size: 22px;
  font-weight: bold;
  text-align: left;
}
.result-image {
  width: 315px;
  margin-top: 2em;
  margin-bottom: 2em;
}
.result-title {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}
.result-answer {
  width: 80%;
  margin-top: 2em;
  font-size: 18px;
  text-align: center;
  @include bp-sm-phone-landscape {
    width: 60%;
  }
}
</style>
