<template>
  <div class="earned-points">
    <p v-if="points !== 0">
      You've earned <span class="points-text"> {{ points }} </span> points!
    </p>
    <p v-else>
      {{ attempted }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Points',
  components: {},
  data() {
    return {};
  },
  props: {
    points: Number,
    attempted: String,
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.earned-points {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.375em;
  padding: 10px;
  background-image: url(../assets/images/bg_nav.png);
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 2px 5px black;
  .points-text {
    font-weight: bold;
  }
}
</style>
